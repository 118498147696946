import { SentryConfig } from 'libs/envs/src/lib/sentry';
import { MapboxConfig } from 'libs/envs/src/lib/mapbox';

export const environment = {
  production: true,
  apiUrl: 'https://api.kolytics.com/api',
  baseApiUrl: 'https://api.kolytics.com',
  customerUrl: 'https://app.kolytics.com',
  adminUrl: 'https://app-admin.kolytics.com',
  queryUrl: 'http://app-query.kolytics.com',
  sentry: {
    ...SentryConfig,
    environment: 'production',
  },
  mapbox: {
    ...MapboxConfig,
  },
};
